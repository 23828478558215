import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

import useOrbEffect from '../helpers/useOrbEffect';

const Container = styled.div`
  height: 100%;
  position: absolute;
  transform: scale(1.15);
  width: 100%;
`;

interface Props {
  childElement?: HTMLImageElement;
  multiplier: number;
  style?: React.CSSProperties;
  children: React.ReactNode;
}
const OrbContainer: FC<Props> = ({ childElement, children, multiplier, style = {} }) => {
  const { start } = useOrbEffect(childElement, multiplier);

  useEffect(() => start(), [childElement]);

  return <Container style={style}>{children}</Container>;
};

export default OrbContainer;
