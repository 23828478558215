import { useCallback } from 'react';

/**
 * Used to plot a random point within a circle and move a target element to that point,
 * once the move is complete, another point is plotted and moved to, ad infinitum.
 * ( Moves an element to random points within a circle ;) )
 * @param target - The target HTMLElement which will be animated.
 * @param multiplier - Affects the subtlety of the animation.
 * @returns
 */
const useOrbEffect = (target: HTMLElement | undefined, multiplier: number)=> {
    const frames = 10;
    const angle = (Math.PI * 2) / frames;
  
    const styleEntries: any[] = [
      { 'will-change': 'transform' },
      { 'pointer-events': 'auto' }
    ];

    // Compiles a list of key-value-pairs into a string, split by ';'.
    const styleToString = useCallback((styleArray: any[])=> {
      return styleArray.map((style)=> `${Object.keys(style)[0]}: ${Object.values(style)[0]}`).join(';');
    }, []);
  
    // Generates a new x/y coordinate within a circle.
    const generateNewPosition = useCallback((i: number)=> {
      const sign = i % 2 ? -1 : 1;
  
      const x = (Math.cos(angle * i) * Math.random() * sign)*multiplier;
      const y = (Math.sin(angle * i) * Math.random() * sign)*multiplier;
  
      return { x, y };
    }, []);
  
    // Moves the target element to the new x/y coordinate.
    const move = useCallback(()=> {
      const newDelta = (Math.random()*100);
      const nextPosition = generateNewPosition(newDelta);
  
      const newStyle = styleEntries.slice();
      newStyle.push({ transition: 'transform 2s linear' });
      newStyle.push({ transform: `translate3d(${nextPosition.x}px, ${nextPosition.y}px, 0)` });
  
      target && target.setAttribute('style', styleToString(newStyle));
    }, [target]);
  
    // Starts the animation.
    const start = useCallback(()=> {
      // Ensure CSS on target.
      target && target.setAttribute('style', styleToString(styleEntries));
      // Do next animation.
      target && target.addEventListener('transitionend', move);

      // Initiate the animation.
      move();
    }, [target]);
  
    return {
      start
    };
}

export default useOrbEffect;